<template>
<!-- a+b列表页 -->
<el-col  :span="24"  class="itemBox">
    <div class="imgWrap"  @click="jumpDetail(info)">
      <img
        :alt="info.name | defaultName"
        :src="info.pic?info.pic + picSuffix : defaultImage"
      />
    </div>
    <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
    
    <p class="msg">效期：{{info.validDateFromat}}</p>
    <p class="msg">规格：{{info.specification}}</p>
    <p class="msg">厂家：{{info.manufacturerName}}</p>
    <div class="priceBox">
      <span class="price">{{info.price  | capitalize}}</span>
      <del v-if="proType=='A'" class="delPrice">{{info.originalPrice  | capitalize}}</del>
       <span v-if="proType=='A'" class="Anum"> 数量{{info.minOrderNum}}</span>
       <div class="counters"  v-if="proType=='B'" >
          <el-input-number
            size="medium"
            v-model="num"
            @change="handleChange"
            :min="0"
            :max="Number(info.realQuantity)"
             v-if="info.minOrderNum"
            :step="info.minOrderNum ? Number(info.minOrderNum) : '1'"
            step-strictly
          ></el-input-number>
        </div>
    </div>
    
  </el-col>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.counters{
  float: right;
}
.counters /deep/.el-input-number {
     width:90px;
    height: 26px;
    line-height: 24px;
    color: #979797 !important;
}

.counters .el-input-number /deep/.el-input-number__decrease{
 width: 23.5px !important;
  border-radius: 0px;
}
.counters .el-input-number /deep/.el-input-number__increase{
 width: 23.5px !important;
  border-radius: 0px;
}
.counters  .el-input-number /deep/.el-input__inner{
    height: 26px;
    line-height: 26px;
    padding-left:30px;
    padding-right:30px;
}
  .itemBox{
    position: relative;
    width: calc(25% - 20px);
    background: #fff;
    float: left;
    margin-top:8px;
    margin-right:20px;
    padding:14px;
    
    .AddBtn{
      position: absolute;
      bottom:16px;
      right:14px;
      width: 80px;
      text-align: center;
      padding:5px 0;
      font-size: 14px;
      color: #000;
      background: #FFD303;
      
    }
  }
  .imgWrap {
    overflow: hidden;
    img {
      width:100%;
      height:100%;
      margin: 0 auto;
      cursor: pointer;
      &:hover {
        box-shadow: 10px 6px 6px -4px #dedada;
        border-radius: 7px;
      }
    }
  }
  .shopName {
    height: 22px;
    color: #333;
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .priceBox {
    margin-top:10px;
    height:26px;
    line-height:26px;
    font-size: 12px;
    color: #999;
    .price{
        color: #f43c38;
        font-size:16px;
        font-weight: bold;
        display: inline-block;
    }
    .delPrice{
        padding-left:2px;
        color: #929598;
        font-size: 12px;
    }
    .Anum{
      float: right;
    }
  }
  .msg{
        color: #929598;
        font-size: 12px;
        line-height: 20px;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
    }
</style>

<script>
import defaultImage from "@/assets/defaultImage.png";
export default {
  name: "ABComItem",
  data() {
    return {
      picSuffix: localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      status: false,
      num: 0,
      // pics: 1,
      defaultImage: defaultImage,
      promotionMap: "",
    };
  },
  props: {
    info: {
      type: Object,
    },
    proType:{
      type:String
    },
    index:{
      type:Number
    }
  },
  methods: {
    handleChange(value) {
      // console.log(value,'woshih',this.info)
      let params={
        num:value,
        index:this.index
      }
      this.$emit("changeNums",params)
    },
    jumpDetail(info) {
      if(this.proType=='A'){
        this.$router.push({
          name: "ComboDetail",
          query: { productId: info.id, promotionId: info.promotionId },
        });
      }else{
         this.$router.push({ name: "ProductDetail", query: { id: info.id ,isRecentExpiration:0 } });
      }
    },
  },
  created() {
    // console.log(this.info);
    this.num=0
    this.handleChange(0)
  },
  updated() {},
};
</script>
