import { render, staticRenderFns } from "./ABComItem.vue?vue&type=template&id=30766027&scoped=true&"
import script from "./ABComItem.vue?vue&type=script&lang=js&"
export * from "./ABComItem.vue?vue&type=script&lang=js&"
import style0 from "./ABComItem.vue?vue&type=style&index=0&id=30766027&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30766027",
  null
  
)

export default component.exports